import React from 'react'
import styled from 'styled-components'
import IconPlus from '../svg/icon-plus'

const SubtopicMaterialImage = ({
  url,
  caption,
  size,
  onImageClick,
  fullScreen,
  fontSize,
  themeMode,
}) => {
  const captionContent = caption ? <Caption>{caption}</Caption> : ``
  const { width, height } = size
  const isDark = themeMode === 'dark'

  return (
    <Wrap fullScreen={fullScreen} fontSize={fontSize}>
      <IconOuterWrap>
        <BoxOuter w={width} h={height}>
          <BoxInner>
            <img src={url} alt="Image" onClick={() => onImageClick(url)} />
          </BoxInner>
        </BoxOuter>
        {captionContent}
        <IconWrap isDark={isDark} onClick={() => onImageClick(url)}>
          <IconPlus isDark={isDark} />
        </IconWrap>
      </IconOuterWrap>
    </Wrap>
  )
}

export default SubtopicMaterialImage

const Wrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.materialAssetWidth(props.fullScreen, props.fontSize)};
  margin-bottom: 1.3em;

  img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
  }
  p {
    margin-top: 0.5em !important;
    font-style: italic;
    color: ${props => props.theme.colors.greyLight1};
  }
  @media print {
    page-break-inside: avoid;
    img {
      page-break-inside: avoid;
    }
  }
`

const BoxOuter = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: ${props =>
    `${parseFloat(((props.h / props.w) * 100).toFixed(2))}%`};
  background: white;
  position: relative;
`

const BoxInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    max-width: 100%;
    height: auto;
  }
`

const Caption = styled.p`
  && {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const IconOuterWrap = styled.div`
  position: relative;
`

const IconWrap = styled.span`
  cursor: pointer;
  display: block;
  border: 2px solid
    ${props =>
      props.isDark
        ? props.theme.colors.greyDark2
        : props.theme.colors.greyLight1};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  top: 0px;
  right: -42px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }
`
