import React from 'react'
import theme from '../../styles/theme'

const IconUpright = ({ isDark }) => {
  const fill = isDark ? theme.colors.white : theme.colors.greyDark1

  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.25V1.5H8.13281L0.695312 8.9375L1.57812 9.82031L9 2.39844V9.5H10.25V0.25H1Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconUpright
