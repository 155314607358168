import React from 'react'
import { withTheme } from 'styled-components'

const IconSearch = ({ isLight, theme }) => {
  const fill = isLight ? theme.materialTheme.fg : theme.colors.white

  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0186 16.3047L13.4961 11.7822C14.4521 10.5576 14.9893 9.05371 14.9893 7.46387C14.9893 5.58398 14.2588 3.82227 12.9268 2.50098C11.6055 1.17969 9.84375 0.449219 7.96387 0.449219C6.09473 0.449219 4.33301 1.17969 3.00098 2.50098C1.67969 3.82227 0.949219 5.58398 0.949219 7.46387C0.949219 9.34375 1.67969 11.1055 3.00098 12.4268C4.33301 13.748 6.09473 14.4785 7.96387 14.4785C9.55371 14.4785 11.0576 13.9521 12.2822 12.9961L16.8047 17.5186L18.0186 16.3047ZM4.2041 11.2129C3.20508 10.2139 2.65723 8.88184 2.65723 7.46387C2.65723 6.0459 3.20508 4.71387 4.21484 3.7041C5.21387 2.70508 6.5459 2.15723 7.96387 2.15723C9.38184 2.15723 10.7139 2.70508 11.7236 3.7041C12.7227 4.71387 13.2705 6.0459 13.2705 7.46387C13.2705 8.88184 12.7227 10.2139 11.7236 11.2129C10.7139 12.2227 9.38184 12.7705 7.96387 12.7705C6.5459 12.7705 5.21387 12.2227 4.2041 11.2129Z"
        fill={fill}
      />
    </svg>
  )
}

export default withTheme(IconSearch)
