import React from 'react'

const IconAa = ({ fontSize, fg, fgAlt }) => {
  const colorActive = fg ? fg : `#ffffff`
  const colorInactive = fgAlt ? fgAlt : `#ffffff`

  return (
    <svg
      width="24"
      height="13"
      viewBox="0 0 24 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.483 13L7.315 0.346H5.358L0.209 13H2.071L3.439 9.485H9.139L10.488 13H12.483ZM8.55 7.927H4.047L6.327 2.075L8.55 7.927Z"
        fill={fontSize === 'md' ? colorInactive : colorActive}
      />
      <path
        d="M23.198 13L19.39 3.676H17.948L14.154 13H15.526L16.534 10.41H20.734L21.728 13H23.198ZM20.3 9.262H16.982L18.662 4.95L20.3 9.262Z"
        fill={fontSize === 'md' ? colorActive : colorInactive}
      />
    </svg>
  )
}

export default IconAa
