import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
require('prismjs/themes/prism.css')

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Don't output empty paragraphs
      if (children[0] === '') {
        return null
      } else {
        return <p>{children}</p>
      }
    },
    [INLINES.HYPERLINK]: (node, children) => {
      if (!node.data) return null
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    },
  },
}

const SubtopicMaterialNote = ({ copy, fullScreen, fontSize }) => {
  //const title = props.title ? <h2>{props.title}</h2> : null
  return (
    <Wrap fullScreen={fullScreen} fontSize={fontSize}>
      {documentToReactComponents(copy, options)}
    </Wrap>
  )
}

SubtopicMaterialNote.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.object,
}

export default SubtopicMaterialNote

const Wrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.materialWidth(props.fullScreen, props.fontSize)};
  margin-bottom: 1.3em;
  background: ${props => props.theme.materialTheme.tipBg};
  padding: 1.3em;
  border-left: 6px solid ${props => props.theme.colors.highlight1};

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    padding: 0 0 !important;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 100%;
  }
  h3 {
    font-size: 1em;
  }

  && p {
    max-width: 100%;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight1};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  && ul,
  && ol {
    max-width: 100%;
  }

  ul,
  ol {
    li {
      p {
        margin-bottom: 0px;
        margin-left: 0;
        margin-right: 0;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      margin-bottom: 0px;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }

  && code {
    background: ${props => props.theme.materialTheme.tipBg2};
  }

  @media print {
    margin: 0 0 1.2em 0;
    padding-bottom: 0.7em;
    border: 2px solid ${props => props.theme.colors.base};
  }
`
