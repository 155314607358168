import React from 'react'
import theme from '../../styles/theme'

const IconPlus = ({ isDark }) => {
  const fill = isDark ? theme.colors.white : theme.colors.greyDark1

  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.75 5.25H6V0.5H4.75V5.25H0V6.5H4.75V11.25H6V6.5H10.75V5.25Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconPlus
