import React from 'react'
import styled from 'styled-components'
import IconSearch from '../svg/icon-search'
import { Link } from 'gatsby'

const SearchCta = ({ isLight, backUrl, isMaterial }) => {
  return (
    <El>
      <Link to="/search" state={{ prevPath: backUrl }}>
        <Icon isMaterial={isMaterial}>
          <IconSearch isLight={isLight} />
        </Icon>
        <Label isLight={isLight}>Search</Label>
      </Link>
    </El>
  )
}

export default SearchCta

const El = styled.div`
  a {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;
  }
`

const Icon = styled.span`
  display: block;
  ${props =>
    props.theme.circleButton(
      props.isMaterial
        ? props.theme.materialTheme.highlight
        : props.theme.colors.highlight1
    )};
  margin-right: 10px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    svg {
      width: 16px;
    }
  }
`

const Label = styled.span`
  color: ${props =>
    props.isLight ? props.theme.materialTheme.fg : props.theme.colors.white};
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }
`
