import React, { useState } from 'react'
import styled from 'styled-components'
import MenuHead from './menu-head'
import Scrollspy from 'react-scrollspy'
import MenuToggle from './menu-toggle'
import MobileToggle from './menu-mobile-toggle'
import useWindowSize from '@rehooks/window-size'

const SubtopicMenu = ({ menuItems, handlePrefsUpdate, prefs }) => {
  const [mobileToggled, setMobileMenuToggled] = useState(false)
  const windowSize = useWindowSize()
  const { innerWidth } = windowSize
  const isMobile = innerWidth < 900

  const onToggle = () => {
    handlePrefsUpdate('fullScreen', !prefs.fullScreen)
  }

  const onMobileMenuToggle = () => {
    setMobileMenuToggled(!mobileToggled)
  }

  const itemSlugs = []
  const items = menuItems.map(function(item, i) {
    const key = `item-${i}`
    const { heading, subHeadings } = item

    let jumpSubItems
    if (subHeadings.length > 0) {
      jumpSubItems = subHeadings.map(function(subItem, x) {
        const subkey = `sub-item-${x}`
        return (
          <JumpSubItem
            key={subkey}
            isFirst={x === 0}
            isLast={x + 1 === subHeadings.length}
          >
            <a href={`#${subItem.slug}`}>{subItem.title}</a>
          </JumpSubItem>
        )
      })
    }
    itemSlugs.push(`jump-${heading.slug}`)

    return (
      <JumpItem key={key}>
        <a href={`#jump-${heading.slug}`}>{heading.title}</a>
        {jumpSubItems}
      </JumpItem>
    )
  })

  const { fullScreen, themeMode } = prefs
  const menuVisible =
    isMobile && mobileToggled ? true : !isMobile && !fullScreen ? true : false

  return (
    <El isToggled={menuVisible} fullScreen={fullScreen}>
      <MobileToggle
        isMobile={isMobile}
        isToggled={mobileToggled}
        onToggle={onMobileMenuToggle}
        themeToggle={themeMode}
      />
      <MenuToggle
        onToggle={onToggle}
        isToggled={fullScreen}
        themeToggle={themeMode}
      />
      {menuVisible ? (
        <MenuBody
          handlePrefsUpdate={handlePrefsUpdate}
          prefs={prefs}
          itemSlugs={itemSlugs}
          items={items}
        />
      ) : null}
    </El>
  )
}

const MenuBody = ({ handlePrefsUpdate, prefs, itemSlugs, items }) => {
  return (
    <BodyEl prefs={prefs}>
      <MenuHead handlePrefsUpdate={handlePrefsUpdate} prefs={prefs} />
      <Inner>
        <Scrollspy
          items={itemSlugs}
          currentClassName="is-current"
          offset={-140}
        >
          {items}
        </Scrollspy>
      </Inner>
    </BodyEl>
  )
}

export default SubtopicMenu

const El = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  width: calc(20% + 36px);
  background: transparent;
  height: 100%;
  z-index: 500;
  overflow-y: ${props => (props.isToggled ? `inherit` : `scroll`)};
  pointer-events: ${props => (props.fullScreen ? `none` : `auto`)};
  border-left: ${props =>
    props.fullScreen ? `none` : `1px solid ${props.theme.materialTheme.sep}`};
  @media print {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    width: calc(20% + 21px);
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    width: ${props => (props.isToggled ? `100%` : `0%`)};
    z-index: 30;
    overflow-y: ${props => (props.isToggled ? `scroll` : `visible`)};
  }
`

const BodyEl = styled.div`
  background: transparent;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* padding-right: 35px; */
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    /* padding-right: 35px; */
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-right: 0px;
    background: ${props =>
      props.prefs.themeMode === 'light'
        ? props.theme.materialThemeOptions.light.bg
        : props.theme.materialThemeOptions.dark.bg};
  }
`

const JumpSubItem = styled.div`
  display: none;
  && a {
    background: ${props => props.theme.materialTheme.bgShade};
    display: block;
    text-decoration: none;
    transition: color 0.1s linear;
    padding: 10px 30px 10px 20px;
    padding-top: ${props => (props.isFirst ? `20px` : `10px`)};
    padding-bottom: ${props => (props.isLast ? `20px` : `10px`)};
    font-weight: normal !important;
    line-height: 130%;
    color: ${props => props.theme.materialTheme.fg};
    ${props => props.theme.fontSizing(props.theme.font.navMiniSizes)};
    border-bottom: none;
    text-decoration: none;
    &:hover {
      opacity: 0.9;
    }
  }
`

const JumpItem = styled.div`
  &.is-current {
    a {
      border-bottom-color: ${props => props.theme.materialTheme.fg};
      border-width: 2px;
      font-weight: bold;
    }
    ${JumpSubItem} {
      display: block;
    }
  }
  a {
    display: block;
    text-decoration: none;
    transition: color 0.1s linear;
    padding: 13px 13px 13px 16px;
    line-height: 130%;
    color: ${props => props.theme.materialTheme.fg};
    ${props => props.theme.fontSizing(props.theme.font.navMiniSizes)};
    border-bottom: 1px solid ${props => props.theme.materialTheme.sep};
    text-decoration: none;
    &:hover {
      opacity: 0.9;
      background: ${props => props.theme.materialTheme.bgShade};
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    a {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
`

const Inner = styled.div`
  padding-top: 100px;
  padding-bottom: 190px;
  ul {
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 76px;
    padding-bottom: 140px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 80px;
    padding-bottom: 5em;
  }
`
