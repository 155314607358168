import React, { Component } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Lightbox from 'react-image-lightbox'

export default class SubtopicLightbox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clickedImage && !this.state.isOpen) {
      const currImageIndex = _.findIndex(nextProps.images, {
        src: nextProps.clickedImage,
      })
      if (currImageIndex > -1) {
        this.setState({
          photoIndex: currImageIndex,
          isOpen: true,
        })
      }
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { images } = this.props

    if (isOpen) {
      return (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          enableZoom={false}
          imagePadding={80}
          onCloseRequest={() => {
            this.setState({ isOpen: false })
            this.props.onClose()
          }}
          onMovePrevRequest={() =>
            this.setState({
              photoIndex: (photoIndex + images.length - 1) % images.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              photoIndex: (photoIndex + 1) % images.length,
            })
          }
          imageCaption={
            images[photoIndex].caption ? (
              <Caption>{images[photoIndex].caption}</Caption>
            ) : null
          }
        />
      )
    } else {
      return null
    }
  }
}

const Caption = styled.div`
  padding: 20px;
`
