import React from 'react'
import styled from 'styled-components'
import { DefaultPlayer as Video } from 'react-html5video'

const SubtopicMaterialVideo = ({ url, caption, fullScreen, fontSize }) => {
  const captionContent = caption ? <p>{caption}</p> : ``

  return (
    <Wrap fullScreen={fullScreen} fontSize={fontSize}>
      <Video
        autoPlay
        loop
        muted
        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
        poster=""
        onCanPlayThrough={() => {
          // Do stuff
        }}
      >
        <source src={url} type="video/mp4" />
      </Video>
      {captionContent}
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.materialAssetWidth(props.fullScreen, props.fontSize)};
  margin-bottom: 1em;
  && p {
    max-width: 100%;
    margin-top: 0.5em !important;
    font-style: italic;
    color: ${props => props.theme.colors.greyLight1};
  }
`

export default SubtopicMaterialVideo
