import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Button } from '../page/components'
import { Flex, Box } from 'reflexbox'
import GridMarks from '../global/grid-marks'
import LogomarkHorizontal from '../svg/logomark-horizontal'
// import IconMenu from '../svg/icon-hamburger'
import IconSearch from '../svg/icon-search'
import IconClose from '../svg/icon-close'
// import SignOut from '../sign-out'

export default () => {
  const [menuToggled, setMenuToggled] = useState(false)

  const handleMenuToggle = () => {
    setMenuToggled(!menuToggled)
  }

  return (
    <Header>
      <GridMarks />
      <Flex flexWrap="nowrap" width={['100%']}>
        <Box width={[3 / 5]} display="flex" alignItems="center">
          <Link to="/">
            <LogomarkHorizontal />
          </Link>
        </Box>
        <Box width={[1 / 5]} display="flex" justifyContent="flex-end">
          <Link to="/search" state={{ prevPath: '/' }}>
            <Icon>
              <IconSearch />
            </Icon>
          </Link>
        </Box>
        <Box width={[1 / 5]} display="flex" justifyContent="flex-end">
          {/* <Button style="no-style" onClick={handleMenuToggle}>
            <Icon>
              <IconMenu />
            </Icon>
          </Button> */}
          &nbsp;
        </Box>
      </Flex>

      <Menu isVisible={menuToggled}>
        <MenuHeader>
          <GridMarks />
          <Flex flexWrap="nowrap" width={['100%']}>
            <Box width={[3 / 6]} display="flex" alignItems="center">
              <Link to="/">
                <LogomarkHorizontal />
              </Link>
            </Box>
            <Box width={[3 / 6]} display="flex" justifyContent="flex-end">
              <Button style="no-style" onClick={handleMenuToggle}>
                <Icon>
                  <IconClose />
                </Icon>
              </Button>
            </Box>
          </Flex>
        </MenuHeader>
        <Flex width={['100%']} pb={[3]}>
          <Box width={[1]} display="flex">
            <List>
              <ListInner>
                <Link to="/">...</Link>
              </ListInner>
            </List>
          </Box>
        </Flex>
      </Menu>
    </Header>
  )
}

const Header = styled.div`
  padding-left: 14px;
  padding-right: 14px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  z-index: 10;
  background: ${props => props.theme.colors.greyDark};
`

const Icon = styled.span`
  display: block;
  ${props => props.theme.circleButton(props.theme.colors.highlight1)};
`

const Menu = styled.div`
  display: ${props => (props.isVisible ? `block` : `none`)};
  background: ${props => props.theme.colors.greyDark};
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
`

const MenuHeader = styled(Header)`
  position: relative;
`

const List = styled.div`
  padding-left: 14px;
  padding-right: 14px;
  width: 100%;
  a {
    color: ${props => props.theme.colors.white};
    display: block;
    padding: 13px 20px 13px 0px;
    border-bottom: 2px solid ${props => props.theme.colors.greyDark2};
    text-decoration: none;
  }
`

const ListInner = styled.div`
  border-top: 2px solid ${props => props.theme.colors.greyDark2};
`
