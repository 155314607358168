import React from 'react'

const IconSun = ({ fg }) => {
  const color = fg ? fg : `#ffffff`

  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.54883 10.8281C5.54883 10.5381 5.58105 10.248 5.62402 9.96875H0.84375V11.6875H5.62402C5.58105 11.4082 5.54883 11.1182 5.54883 10.8281ZM17.7197 9.96875C17.7627 10.248 17.7949 10.5381 17.7949 10.8281C17.7949 11.1182 17.7627 11.4082 17.7197 11.6875H22.5V9.96875H17.7197ZM16.5596 7.1543L19.9326 3.78125L18.7188 2.56738L15.335 5.94043C15.7969 6.29492 16.2051 6.70312 16.5596 7.1543ZM6.78418 14.502L3.41113 17.875L4.625 19.0889L7.99805 15.7158C7.54688 15.3613 7.12793 14.9531 6.78418 14.502ZM12.5312 4.78027V0H10.8125V4.78027C11.0918 4.7373 11.3818 4.70508 11.6719 4.70508C11.9619 4.70508 12.252 4.7373 12.5312 4.78027ZM10.8125 16.876V21.6562H12.5312V16.876C12.252 16.9189 11.9619 16.9512 11.6719 16.9512C11.3818 16.9512 11.0918 16.9189 10.8125 16.876ZM15.335 15.7158L18.7188 19.0889L19.9326 17.875L16.5596 14.502C16.2051 14.9531 15.7969 15.3721 15.335 15.7158ZM7.99805 5.94043L4.625 2.56738L3.41113 3.78125L6.78418 7.1543C7.12793 6.70312 7.54688 6.29492 7.99805 5.94043ZM11.6719 6.1875C9.11523 6.1875 7.03125 8.27148 7.03125 10.8281C7.03125 13.3848 9.11523 15.4688 11.6719 15.4688C14.2285 15.4688 16.3125 13.3848 16.3125 10.8281C16.3125 8.27148 14.2285 6.1875 11.6719 6.1875ZM11.6719 13.7822C10.0391 13.7822 8.71777 12.4609 8.71777 10.8281C8.71777 9.19531 10.0391 7.87402 11.6719 7.87402C13.3047 7.87402 14.626 9.19531 14.626 10.8281C14.626 12.4609 13.3047 13.7822 11.6719 13.7822Z"
        fill={color}
      />
    </svg>
  )
}

export default IconSun
