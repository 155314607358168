import React from 'react'
import styled from 'styled-components'
import IconUpright from '../svg/icon-upright'

const SubtopicMaterialAsset = ({
  url,
  text,
  fullScreen,
  themeMode,
  fontSize,
}) => {
  const isDark = themeMode === 'dark'

  //console.log('isDark', isDark, themeMode)

  return (
    <Wrap fullScreen={fullScreen} fontSize={fontSize}>
      <Asset href={url} target="_blank">
        {text}
        <IconWrap isDark={isDark}>
          <IconUpright isDark={isDark} />
        </IconWrap>
      </Asset>
    </Wrap>
  )
}

export default SubtopicMaterialAsset

const Wrap = styled.div`
  margin-left: auto;
  margin-right: auto;
  ${props => props.theme.materialWidth(props.fullScreen, props.fontSize)};
`

const Asset = styled.a`
  position: relative;
  display: inline-block;
  padding-right: 40px;
`

const IconWrap = styled.span`
  display: block;
  border: 2px solid
    ${props =>
      props.isDark
        ? props.theme.colors.greyDark2
        : props.theme.colors.greyLight1};
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: absolute;
  top: -1px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
