import React from 'react'
import styled from 'styled-components'
import useWindowSize from '@rehooks/window-size'

const SubtopicHero = ({ hero }) => {
  if (!hero) return null

  const windowSize = useWindowSize()

  let srcSet = hero.fluid.srcSet
  const { url, details } = hero.file
  const { width, height } = details.image

  const maxWidth = 3000
  if (width > maxWidth) {
    const ratio = width / height
    const newMaxHeight = maxWidth / ratio

    const regW = new RegExp(width, 'g')
    srcSet = srcSet.replace(regW, maxWidth)

    const regH = new RegExp(height, 'g')
    srcSet = srcSet.replace(regH, newMaxHeight)
  }

  const heroHeight = windowSize ? windowSize.innerWidth * 0.25 : 300

  return (
    <Hero>
      <Inner height={heroHeight}>
        <img srcSet={srcSet} src={url} />
      </Inner>
    </Hero>
  )
}

export default SubtopicHero

const Hero = styled.div``

const Inner = styled.div`
  overflow: hidden;
  height: ${props => `${props.height}px`};
`
