import React from 'react'
import styled from 'styled-components'
import IconMoon from '../svg/icon-moon'
import IconSun from '../svg/icon-sun'
import IconAa from '../svg/icon-aa'
import theme from '../../styles/theme'

const MenuHead = ({ handlePrefsUpdate, prefs }) => {
  const fontSizeToggle = prefs.fontSize === 'md' ? 'lg' : 'md'
  const themeToggle = prefs.themeMode === 'light' ? 'dark' : 'light'
  const themeSettings = theme.materialThemeOptions[prefs.themeMode]

  // console.log('themeSettings', themeSettings)

  return (
    <El>
      <Toggle onClick={() => handlePrefsUpdate('themeMode', themeToggle)}>
        <ToggleOn>
          <IconMoon fg={themeSettings.fg} />
        </ToggleOn>
        <ToggleOff>
          <IconSun fg={themeSettings.fg} />
        </ToggleOff>
        <ToggleControl themeToggle={themeToggle} />
      </Toggle>
      <Icon>
        <button onClick={() => handlePrefsUpdate('fontSize', fontSizeToggle)}>
          <IconAa
            fontSize={prefs.fontSize}
            fg={themeSettings.fg}
            fgAlt={themeSettings.sep}
          />
        </button>
      </Icon>
    </El>
  )
}

export default MenuHead

const El = styled.div`
  position: absolute;
  z-index: 500;
  height: 99px;
  display: flex;
  align-items: center;
  z-index: 1;
  padding-left: 16px;
  width: 100%;
  /* border-bottom: 3px solid ${props => props.theme.materialTheme.sep}; */
  background: ${props => props.theme.materialTheme.bg};
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 75px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    height: 80px;
    width: 100%;
    padding-left: 11px;
  }
`

const Icon = styled.div`
  ${props => props.theme.circleButton(props.theme.materialTheme.sep)};
  margin-right: 20px;
  button {
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    svg {
      width: 20px;
    }
  }
`

const Toggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  width: 96px;
  border-radius: 48px;
  border: 2px solid ${props => props.theme.materialTheme.sep};
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 40px;
    width: 83px;
  }
`

const ToggleOn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 40px;
    width: 40px;
    svg {
      width: 14px;
    }
  }
`

const ToggleOff = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 40px;
    width: 40px;
    svg {
      width: 20px;
    }
  }
`

const ToggleControl = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 48px;

  border: 2px solid ${props => props.theme.materialTheme.sep};
  background: ${props => props.theme.materialTheme.bg};
  position: absolute;
  transition: all 0.2s ease-out;
  left: ${props =>
    props.themeToggle === 'dark' ? `-2px` : `calc(100% - 46px)`};
  top: -2px;
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 40px;
    width: 40px;
    left: ${props =>
      props.themeToggle === 'dark' ? `-2px` : `calc(100% - 38px)`};
  }
`
