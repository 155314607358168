import React from 'react'
import styled from 'styled-components'
import IconExpand from '../svg/icon-expand'
import IconContract from '../svg/icon-contract'
import theme from '../../styles/theme'

const Toggle = ({ isToggled, onToggle, themeToggle }) => {
  const iconFill =
    themeToggle === 'light' ? theme.colors.greyDark1 : theme.colors.white

  // console.log('iconFill', themeToggle, iconFill)

  const isDark = isToggled
  const icon = isToggled ? (
    <IconContract fill={iconFill} />
  ) : (
    <IconExpand fill={iconFill} />
  )

  return (
    <El isDark={isDark}>
      <button onClick={onToggle}>{icon}</button>
    </El>
  )
}

export default Toggle

const El = styled.div`
  position: absolute;
  right: 35px;
  top: 26px;
  z-index: 2;
  pointer-events: auto;
  button {
    ${props => props.theme.circleButton(props.theme.materialTheme.sep)};
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    top: 17px;
    svg {
      width: 13px;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    display: none;
  }
`
