import React from 'react'

const IconExpand = ({ fill }) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37207 8.90332L1.96875 14.3174V9.34375H0.25V17.25H8.15625V15.5312H3.18262L8.59668 10.1172L7.37207 8.90332ZM8.84375 0.75V2.46875H13.8174L8.40332 7.87207L9.62793 9.09668L15.0312 3.68262V8.65625H16.75V0.75H8.84375Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconExpand
