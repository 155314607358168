import React from 'react'

const IconContract = ({ fill }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1904 1.52344L16.9766 0.30957L11.5625 5.72363V0.75H9.84375V8.65625H17.75V6.9375H12.7764L18.1904 1.52344ZM1.25 11.0625H6.22363L0.80957 16.4766L2.02344 17.6904L7.4375 12.2764V17.25H9.15625V9.34375H1.25V11.0625Z"
        fill={fill}
      />
    </svg>
  )
}

export default IconContract
