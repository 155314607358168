import React from 'react'
import styled from 'styled-components'
//import IconExpand from '../svg/icon-expand'
//import IconContract from '../svg/icon-contract'

import IconClose from '../svg/icon-close'
import IconHamburger from '../svg/icon-hamburger'
//import theme from '../../styles/theme'

const MobileToggle = ({ isToggled, onToggle, themeToggle }) => {
  const icon = isToggled ? (
    <IconClose isDark={true} />
  ) : (
    <IconHamburger isDark={themeToggle === 'dark' ? true : false} />
  )

  return (
    <El isDark={isToggled}>
      <button onClick={onToggle}>{icon}</button>
    </El>
  )
}

export default MobileToggle

const El = styled.div`
  position: absolute;
  right: 11px;
  top: 16px;
  z-index: 2;
  display: none;
  button {
    ${props =>
      props.theme.circleButton(
        props.isDark
          ? props.theme.colors.highlight1
          : props.theme.colors.highlight1
      )};
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    display: block;
    right: 14px;
    top: 18px;
  }
`
