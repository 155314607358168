import React from 'react'

const IconMoon = ({ fg }) => {
  const color = fg ? fg : `#ffffff`

  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0967 13.168C11.4756 13.3613 8.88672 11.9971 7.65137 9.51562C6.40527 7.04492 6.86719 4.15527 8.58594 2.17871C9.13379 1.53418 9.81055 0.99707 10.6162 0.588867C9.68164 0.416992 8.72559 0.395508 7.76953 0.545898C6.9209 0.674805 6.09375 0.943359 5.28809 1.34082C1.13086 3.4248 -0.566406 8.46289 1.50684 12.5986C3.56934 16.7344 8.61816 18.3887 12.7754 16.3154C13.5811 15.9072 14.29 15.3916 14.9023 14.8008C15.6006 14.124 16.1592 13.3398 16.5781 12.4912C15.7725 12.8887 14.9346 13.1143 14.0967 13.168ZM12.0127 14.7793C8.7041 16.4336 4.68652 15.1123 3.03223 11.8252C1.38867 8.53809 2.74219 4.52051 6.05078 2.86621C6.06152 2.85547 6.08301 2.85547 6.10449 2.84473C4.97656 5.11133 4.90137 7.86133 6.11523 10.2891C7.3291 12.7168 9.57422 14.2959 12.0664 14.7578C12.0449 14.7686 12.0342 14.7793 12.0127 14.7793Z"
        fill={color}
      />
    </svg>
  )
}

export default IconMoon
