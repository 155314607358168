import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import _ from 'lodash'
import styled from 'styled-components'

import { compose } from 'recompose'
import useComponentSize from '@rehooks/component-size'
import { withAuthorization } from '../components/session'
import { withFirebase } from '../components/firebase'

import SEO from '../components/SEO'
import { Wrap } from '../components/page/components'
import SubtopicHeader from '../components/subtopic/header'
import SubtopicMenu from '../components/subtopic/menu'
import SubtopicMaterial from '../components/subtopic/material'
import SubtopicLightbox from '../components/subtopic/lightbox'
import SubtopicHero from '../components/subtopic/hero'
import theme from '../styles/theme'

import Logo from '../images/shillington-logo.png'

const SubTopicBase = ({ data, location, authUser, updateAuthUserPrefs }) => {
  if (!data) return null

  const [menuItems, setMenuItems] = useState([])
  const [menuBlocks, setMenuBlocks] = useState([])
  const [pageImages, setPageImages] = useState([])
  const [pageImageClick, setPageImageClick] = useState(false)
  const materialEl = useRef(null)
  const materialElSize = useComponentSize(materialEl)

  useEffect(
    () => {
      handleRenderedHeadings()
    },
    [menuItems]
  )

  const handlePrefsUpdate = (setting, val) => {
    if (authUser.prefs) {
      const newPrefs = _.cloneDeep(authUser.prefs)
      newPrefs[setting] = val
      updateAuthUserPrefs(newPrefs)
      handleRenderedHeadings()
    }
  }

  const handleRenderedHeadings = () => {
    setTimeout(() => {
      if (materialEl.current) {
        const sections = materialEl.current.querySelectorAll('.section-heading')
        const els = []
        let prevColor = 'red'
        let height = 0
        let lastOffset = 0

        for (let i = 0; i < sections.length; i++) {
          height = sections[i].offsetTop - lastOffset
          lastOffset = sections[i].offsetTop
          prevColor = prevColor === 'red' ? 'blue' : 'red'

          const elId = i === 0 ? `faux-block` : `jump-${sections[i - 1].id}`
          const key = `block-${i}`
          const el = (
            <Block height={`${height}px`} key={key} bg={prevColor} id={elId} />
          )
          els.push(el)
        }
        setMenuBlocks(els)
      }
    }, 1500)
  }

  const handlePageImages = images => {
    setPageImages(_.cloneDeep(images))
  }

  const handlePageImageClick = image => {
    setPageImageClick(image)
  }

  const handleMenuItems = (items, subItems) => {
    const itemsWithSubs = []

    for (let i = 0; i < items.length; i++) {
      const itemHeading = _.clone(items[i])
      let subItemHeadings = []
      if (subItems[i]) {
        subItemHeadings = _.cloneDeep(subItems[i])
      }
      itemsWithSubs[i] = {
        heading: itemHeading,
        subHeadings: subItemHeadings,
      }
    }
    setMenuItems(itemsWithSubs)
  }

  const onLightboxClose = () => {
    setPageImageClick(false)
  }

  const getSubTopicByReferenceId = () => {}

  const { pathname } = location
  const { subTopic } = data
  const { title, topic, material, hero } = subTopic

  // console.log('subTopic', subTopic)

  const { prefs } = authUser
  const { fullScreen, themeMode, fontSize } = prefs
  const themeSettings = theme.materialThemeOptions[themeMode]

  return (
    <>
      <Wrap bg={themeSettings.bg}>
        <SEO title={title} />
        <Page fullScreen={fullScreen} themeMode={themeMode}>
          <PrintLogo>
            <img src={Logo} />
          </PrintLogo>
          <PrintFoot>&copy; Shillington Education 2019</PrintFoot>
          <SubtopicHeader
            topic={topic}
            subtopic={subTopic}
            title={title}
            pathname={pathname}
            pageSize={materialElSize}
            fullScreen={fullScreen}
            themeMode={themeMode}
          />
          <PageInner ref={materialEl} fullScreen={fullScreen}>
            <SubtopicHero hero={hero} />
            <SubtopicMaterial
              title={title}
              data={material}
              setPageImages={handlePageImages}
              onImageClick={handlePageImageClick}
              setPageMenuItems={handleMenuItems}
              getReferenceSubTopic={getSubTopicByReferenceId}
              fullScreen={fullScreen}
              themeMode={themeMode}
              fontSize={fontSize}
            />
            <div className="section-heading">&nbsp;</div>
            <Blocks fullScreen={fullScreen}>{menuBlocks}</Blocks>
          </PageInner>
          <SubtopicMenu
            menuItems={menuItems}
            handlePrefsUpdate={handlePrefsUpdate}
            prefs={prefs}
          />
          <SubtopicLightbox
            images={pageImages}
            clickedImage={pageImageClick}
            onClose={onLightboxClose}
          />
        </Page>
      </Wrap>
    </>
  )
}

// const Wrap = styled.div`
//   position: relative;
//   z-index: 40;
//   @media print {
//     padding-left: 140px;
//     padding-right: 140px;
//   }
// `

const PageInner = styled.div`
  padding-top: ${props => (props.fullScreen ? `0px` : `100px`)};
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 110px;
  }
  @media print {
    padding-top: 0px;
  }
`

const Page = styled.div`
  background: ${props => props.theme.materialTheme.bg};
  position: relative;
  padding-right: ${props =>
    props.fullScreen ? `0%` : `calc(${(1 / 5) * 100}%)`};
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-right: 0px;
  }
  @media print {
    padding-right: 0px;
  }
`

const Blocks = styled.div`
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  display: ${props => (props.fullScreen ? `none` : `block`)};
  @media print {
    display: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    left: -20px;
  }
`

const Block = styled.div`
  background: transparent;
  pointer-events: none;
  /* background: ${props => props.bg}; */
  width: 100%;
  height: ${props => props.height};
`

const PrintLogo = styled.div`
  display: none;
  width: 70px;
  height: 70px;
  position: fixed;
  left: 0;
  top: 0px;
  @media print {
    display: block;
  }
`

const PrintFoot = styled.div`
  display: none;
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 110px;
  font-size: 0.45em;
  @media print {
    display: block;
  }
`

export const query = graphql`
  query($slug: String!) {
    subTopics: allContentfulSubtopic {
      edges {
        node {
          title
          id
          slug
          publishDate
          contentful_id
        }
      }
    }
    subTopic: contentfulSubtopic(slug: { eq: $slug }) {
      title
      slug
      publishDate(formatString: "MMMM DD, YYYY")
      publishDateISO: publishDate(formatString: "YYYY-MM-DD")
      isActive
      code
      hero {
        fluid {
          srcSet
        }
        file {
          details {
            size
            image {
              width
              height
            }
          }
          url
        }
      }
      material {
        nodeType
        id
        content {
          nodeType
          content {
            data {
              uri
              target {
                fields {
                  file {
                    en_US {
                      url
                      details {
                        size
                      }
                      fileName
                      contentType
                    }
                  }
                }
                sys {
                  type
                  id
                  contentType {
                    sys {
                      type
                      linkType
                      id
                    }
                  }
                }
              }
            }
            marks {
              type
            }
            value
            nodeType
            content {
              marks {
                type
              }
              data {
                uri
                target {
                  sys {
                    id
                  }
                  fields {
                    title {
                      en_US
                    }
                    file {
                      en_US {
                        url
                        fileName
                        contentType
                      }
                    }
                  }
                }
              }
              value
              nodeType
              content {
                data {
                  uri
                }
                marks {
                  type
                }
                value
                nodeType
                content {
                  marks {
                    type
                  }
                  value
                  nodeType
                  content {
                    data {
                      uri
                    }
                    marks {
                      type
                    }
                    value
                    nodeType
                    content {
                      marks {
                        type
                      }
                      value
                      nodeType
                      content {
                        data {
                          uri
                        }
                        marks {
                          type
                        }
                        value
                        nodeType
                        content {
                          marks {
                            type
                          }
                          value
                          nodeType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          data {
            target {
              sys {
                id
                type
                contentType {
                  sys {
                    type
                    linkType
                    id
                  }
                }
              }
              fields {
                url {
                  en_US
                }
                title {
                  en_US
                }
                description {
                  en_US
                }
                type {
                  en_US
                }
                copy {
                  en_US {
                    nodeType
                    content {
                      nodeType
                      content {
                        nodeType
                        value
                        marks {
                          type
                        }
                        data {
                          uri
                        }
                        content {
                          nodeType
                          value
                          marks {
                            type
                          }
                          content {
                            nodeType
                            value
                            marks {
                              type
                            }
                            data {
                              uri
                            }
                            content {
                              nodeType
                              value
                              marks {
                                type
                              }
                              content {
                                nodeType
                                value
                                marks {
                                  type
                                }
                                data {
                                  uri
                                }
                                content {
                                  nodeType
                                  value
                                  marks {
                                    type
                                  }
                                  content {
                                    nodeType
                                    value
                                    marks {
                                      type
                                    }
                                    data {
                                      uri
                                    }
                                    content {
                                      nodeType
                                      value
                                      marks {
                                        type
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                file {
                  en_US {
                    url
                    details {
                      image {
                        width
                        height
                      }
                    }
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
      topic {
        id
        title
        slug
        course {
          slug
          title
        }
      }
    }
  }
`

const condition = authUser => !!authUser

const SubTopic = compose(
  withAuthorization(condition),
  withFirebase
)(SubTopicBase)

export default SubTopic
