import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const MaterialVideo = ({ url, fullScreen, fontSize }) => {
  return (
    <Wrap fullScreen={fullScreen} fontSize={fontSize}>
      <Video>
        <ReactPlayer
          className="react-player"
          url={url}
          width="100%"
          height="100%"
          controls={true}
          playing={false}
        />
      </Video>
    </Wrap>
  )
}

export default MaterialVideo

const Wrap = styled.div`
  ${props => props.theme.materialAssetWidth(props.fullScreen, props.fontSize)};
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.3em;
`

const Video = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`
