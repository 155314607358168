import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import SubtopicMaterialImage from './material-image'
import SubtopicMaterialNote from './material-note'
import SubtopicMaterialVideoOld from './material-video-old'

import GithubSlugger from 'github-slugger'
import SubtopicMaterialAsset from './material-asset'
import MaterialVideo from './material-video'

require('prismjs/themes/prism.css')
//import _ from 'lodash';

export default class SubtopicMaterial extends Component {
  constructor(props) {
    super(props)

    this.state = {
      headings: [],
    }
    this.headingsRendered = []
    this.subHeadingsRendered = []
    this.imagesRendered = []
    this._handleRenderHeading = this._handleRenderHeading.bind(this)
    this._handleRenderImage = this._handleRenderImage.bind(this)
  }

  componentDidMount() {
    const { setPageImages, setPageMenuItems } = this.props
    setPageMenuItems(this.headingsRendered, this.subHeadingsRendered)
    setPageImages(this.imagesRendered)
  }

  getOptions = () => {
    const self = this
    const { themeMode } = this.props

    return {
      renderNode: {
        [BLOCKS.HEADING_2]: node => {
          if (node.content) {
            if (node.content[0]) {
              const title = node.content[0].value
              const slugger = new GithubSlugger()
              const slug = slugger.slug(title)
              self._handleRenderHeading({ title: title, slug: slug })
              return (
                <SectionHeading id={slug} className="section-heading">
                  {title}
                </SectionHeading>
              )
            }
          }
        },
        [BLOCKS.HEADING_3]: node => {
          if (node.content) {
            if (node.content[0]) {
              const title = node.content[0].value
              const slugger = new GithubSlugger()
              const slug = slugger.slug(title)
              //console.log('HEADING_3', slug)
              self._handleRenderSubHeading({ title: title, slug: slug })
              return <SectionSubHeading id={slug}>{title}</SectionSubHeading>
            }
          }
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          //console.log('node 1', node);

          if (node.data) {
            if (node.nodeType === 'embedded-asset-block') {
              if (node.data.target.fields) {
                const { file, description, title } = node.data.target.fields
                const { url, details } = file.en_US
                const { fullScreen, fontSize } = this.props
                const extension = url.split('.').pop()

                if (
                  extension === 'gif' ||
                  extension === 'jpg' ||
                  extension === 'png' ||
                  extension === 'jpeg'
                ) {
                  const caption = description ? description.en_US : null
                  const size = details.image
                  const { onImageClick } = this.props
                  self._handleRenderImage({ src: url, caption: caption })
                  return (
                    <SubtopicMaterialImage
                      url={url}
                      caption={caption}
                      onImageClick={onImageClick}
                      size={size}
                      fullScreen={fullScreen}
                      fontSize={fontSize}
                      themeMode={themeMode}
                    />
                  )
                } else if (extension === 'pdf' || extension === 'zip') {
                  const text = title ? title.en_US : 'Download'
                  return (
                    <SubtopicMaterialAsset
                      url={url}
                      text={text}
                      fullScreen={fullScreen}
                      fontSize={fontSize}
                      themeMode={themeMode}
                    />
                  )
                } else if (extension === 'mp4') {
                  const videoCaption = description ? description.en_US : null
                  return (
                    <SubtopicMaterialVideoOld
                      url={url}
                      caption={videoCaption}
                      fullScreen={fullScreen}
                      fontSize={fontSize}
                    />
                  )
                } else {
                  return null
                }
              } else {
                return null
              }
            } else {
              return null
            }
          } else {
            return null
          }
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          let content

          console.log(node.data)

          if (node.data) {
            if (
              node.data.target &&
              node.data.target.sys &&
              node.data.target.sys.contentType
            ) {
              //console.log('node.data.target', node.data.target.sys);
              const contentType = node.data.target.sys.contentType.sys.id

              const { title, copy, url } = node.data.target.fields
              const { fullScreen, fontSize } = this.props
              switch (contentType) {
                case 'materialNote': {
                  const noteTitle = title ? title.en_US : null

                  content = (
                    <SubtopicMaterialNote
                      title={noteTitle}
                      copy={copy.en_US}
                      fullScreen={fullScreen}
                      fontSize={fontSize}
                    />
                  )
                  break
                }
                case 'materialVideo': {
                  const videoUrl = url ? url.en_US : null
                  content = (
                    <MaterialVideo
                      url={videoUrl}
                      fullScreen={fullScreen}
                      fontSize={fontSize}
                    />
                  )
                  break
                }

                default:
                  content = null
                  break
              }
            }
          }
          return content
        },
        [INLINES.EMBEDDED_ENTRY]: () => {
          return null
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          )
        },
        [INLINES.ASSET_HYPERLINK]: node => {
          let content
          if (
            node.data &&
            node.data.target &&
            node.data.target.sys &&
            node.data.target.fields &&
            node.data.target.fields.file &&
            node.data.target.fields.file.en_US &&
            node.data.target.fields.file.en_US.fileName &&
            node.data.target.fields.file.en_US.url
          ) {
            content = (
              <a
                href={node.data.target.fields.file.en_US.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {node.data.target.fields.file.en_US.fileName}
              </a>
            )
          }
          return content
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          let content
          if (
            node.data &&
            node.data.target &&
            node.data.target.sys &&
            node.data.target.sys.contentType &&
            node.data.target.sys.contentType.sys
          ) {
            if (node.data.target.sys.contentType.sys.id === 'subtopic') {
              const { getReferenceSubTopic } = this.props
              const referenceEntry = getReferenceSubTopic(
                node.data.target.sys.id
              )
              let url = '/'
              if (referenceEntry) {
                url = referenceEntry.node.slug
              }
              content = <Link to={`/${url}`}>{children}</Link>
            }
          }
          return content
        },
      },
    }
  }

  _handleRenderHeading(heading) {
    this.headingsRendered.push(heading)
  }

  _handleRenderSubHeading(heading) {
    const groupIndex = this.headingsRendered.length - 1

    if (this.subHeadingsRendered[groupIndex]) {
      this.subHeadingsRendered[groupIndex].push(heading)
    } else {
      this.subHeadingsRendered[groupIndex] = [heading]
    }
  }

  _handleRenderImage(image) {
    this.imagesRendered.push(image)
  }

  render() {
    const { data, title, fullScreen, fontSize } = this.props
    this.headingsRendered = []

    const options = this.getOptions()

    return (
      <Wrap>
        <Page fullScreen={fullScreen} fontSize={fontSize}>
          <Title fullScreen={fullScreen} fontSize={fontSize}>
            {title}
          </Title>
          {documentToReactComponents(data, options)}
        </Page>
      </Wrap>
    )
  }
}

SubtopicMaterial.propTypes = {
  data: PropTypes.object,
}

const Wrap = styled.div`
  padding-top: 0px;
  padding-bottom: 6em;
  padding-left: 0px;
  padding-right: 0px;
  @media print {
    padding-top: 2em;
  }
`

const Title = styled.div`
  ${props =>
    props.theme.fontSizing(
      props.theme.fontSizingZoom('title', props.fontSize)
    )};
  ${props => props.theme.materialWidth(props.fullScreen, props.fontSize)};

  /* HACK for fine control :) */
  @media screen and (max-width: 1400px) {
    font-size: 68px;
    line-height: 68px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 58px;
    line-height: 58px;
  }

  margin-bottom: 1em;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;

  @media print {
    font-size: 28px;
    padding: 0 0;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    margin-bottom: 0.75em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 0.5em;
    /* HACK because lazy :) */
    font-size: 32px;
    line-height: 34px;
  }
`

const Page = styled.div`
  margin: 0 auto;
  padding-top: 100px;
  color: ${props => props.theme.materialTheme.fg};
  ${props =>
    props.theme.fontSizing(
      props.theme.fontSizingZoom('material', props.fontSize)
    )};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-left: auto;
    margin-right: auto;
    ${props => props.theme.materialWidth(props.fullScreen, props.fontSize)};
    p,
    ul,
    ol {
      max-width: 100%;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 1em;
    text-transform: capitalize;
    padding: 0;
  }

  h2 {
    font-size: 160%;
    line-height: 120%;
  }
  h3 {
    font-size: 122%;
  }
  h4 {
    font-size: 110%;
  }
  h5 {
    font-size: 100%;
  }
  h6 {
    font-size: 100%;
  }

  p {
    /* line-height: 1.6; */
    margin-top: 0;
    margin-bottom: 1.3em;
    padding-right: 1%;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.materialTheme.fg};
    &:hover {
      color: ${props => props.theme.colors.highlight1};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong,
  b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  code,
  p code {
    font-family: ${props => props.theme.font.fontFamilyMono};
    background: ${props => props.theme.materialTheme.tipBg};
    padding: 2px 5px;
    font-size: 90%;
    border-radius: 4px;
  }

  ul,
  ol {
    margin-top: 0px;
    margin-bottom: 2em;
    padding-left: 18px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 1%;
  }

  ul {
    li {
      list-style: disc;
      /* line-height: 1.25; */
      padding-left: 8px;
      p {
        margin-bottom: 0px;
        margin-left: 0;
        margin-right: 0;
        padding-right: 0px;
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      line-height: 1.25;
      padding-left: 8px;
      p {
        margin-bottom: 0px;
        margin-left: 0;
        margin-right: 0;
      }
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid ${props => props.theme.materialTheme.sep};
    margin: 3.2em auto 3em -60px;
    width: calc(100% + 61px);
    @media screen and (max-width: ${props => props.theme.responsive.md}) {
      margin: 2.4em auto 2em -35px;
      width: calc(100% + 36px);
    }
    @media screen and (max-width: ${props => props.theme.responsive.sm}) {
      margin: 1.9em 0 1.7em 0;
      width: 100%;
    }
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
    p {
      max-width: 100%;
    }
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }

  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    padding-top: 49px;
  }

  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-top: 15px;
    ul,
    ol {
      li {
        padding-left: 0px;
      }
    }
  }

  @media print {
    margin: 0 auto;
    max-width: 100%;
    font-size: 10px;
    line-height: 2em;
    img {
      page-break-inside: avoid;
    }
    p {
      margin: 0 0 1.1em 0;
    }
    h1,
    h2,
    h3 {
      padding: 0 0;
    }
    h1 {
    }
    h2 {
    }
    h3 {
    }
    ul,
    ol {
      margin: 0 0 2em 0;
    }
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    blockquote {
      margin-left: 0px;
    }
  }
`

const SectionHeading = styled.h2`
  font-weight: bold;
  font-size: 200%;
  margin-bottom: 1em;
  padding: 0 ${props => props.theme.sizes.innerContentPadding};
  @media print {
    padding: 0 0;
  }
`

const SectionSubHeading = styled.h3`
  font-weight: bold;
  font-size: 150%;
  margin-bottom: 1em;
  padding: 0 ${props => props.theme.sizes.innerContentPadding};
  @media print {
    padding: 0 0;
  }
`

/*
const JumpMenu = styled.div`
  position: fixed;
  top: 0;
  width: 300px;
  background: ${props => props.theme.colors.greyDark};
  padding: 5em 1.5em 3em 1.5em;
  font-size: 90%;
`

const JumpItem = styled.div`
  padding: 10px 0;
  a {
    color: DarkGray;
    text-decoration: none;
    transition: color 0.1s linear;
    &:hover {
      color: white;
    }
  }
`
*/
