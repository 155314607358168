import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
// import Close from './header-close'
import IconArrow from '../svg/icon-arrow'
import SearchCta from '../page/search-cta'
import useWindowSize from '@rehooks/window-size'
import useWindowScrollPosition from '@rehooks/window-scroll-position'
import GridMarks from '../global/grid-marks'
import LogomarkHorizontal from '../svg/logomark-horizontal'
import theme from '../../styles/theme'
import HeaderMobile from './header-mobile'

export default ({
  topic,
  subtopic,
  pathname,
  pageSize,
  fullScreen,
  themeMode,
}) => {
  const windowSize = useWindowSize()
  const options = {
    throttle: 0,
  }
  const position = useWindowScrollPosition(options)
  const { y } = position
  const { height } = pageSize
  const { innerHeight, innerWidth } = windowSize
  const percent = (y / (height - innerHeight)) * 100

  if (!topic || (fullScreen === true && innerWidth >= 900)) return null

  if (innerWidth <= 900) {
    return <HeaderMobile />
  } else {
    const code = subtopic.code ? subtopic.code : ''
    const firstTopic = topic[0]

    const course = firstTopic.course[0]
    let courseUrl = `/${course.slug}`

    // HACK: Update contentful course slug later
    courseUrl = courseUrl.replace('graphic-design-course', 'graphic-design')

    let courseTitle = course.title
    courseTitle = courseTitle.replace('Graphic Design Course', 'Graphic Design')

    // <Close
    //       isDark={themeMode === 'dark'}
    //       target={courseUrl}
    //       pathname={pathname}
    //     />

    const themeSettings = theme.materialThemeOptions[themeMode]

    return (
      <Header>
        <GridMarks bg={themeSettings.gridNib} />

        <Flex flexWrap="nowrap" width={['100%']}>
          <Box width={[1, 1 / 5]} display="flex" alignItems="center">
            <Link to="/">
              <LogomarkHorizontal fg={themeSettings.fg} />
            </Link>
          </Box>
          <Box width={[1, 2 / 5]} display="flex" alignItems="center">
            <Link to={courseUrl}>{courseTitle}</Link>{' '}
            <Sep>
              <IconArrow />
            </Sep>
            {firstTopic.title}
            {/* <Code>{code}</Code> */}
          </Box>
          <Box width={[1, 1 / 5]} display="flex" alignItems="center">
            <SearchCta
              isLight={themeMode === 'light'}
              isMaterial={true}
              backUrl={pathname}
            />
          </Box>
        </Flex>
        <IndicatorWrap>
          <Indicator width={`${percent}%`} />
        </IndicatorWrap>
      </Header>
    )
  }
}

const Header = styled.div`
  height: 100px;
  background: ${props => props.theme.materialTheme.bg};
  position: fixed;
  left: 0;
  top: 0;
  z-index: 500;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.materialTheme.fg};
  border-bottom: 1px solid ${props => props.theme.materialTheme.sep};
  a {
    color: ${props => props.theme.materialTheme.fg};
    text-decoration: none;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    height: 76px;
    padding-left: 35px;
    padding-right: 35px;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    padding-left: 14px;
    padding-right: 14px;
  }
`

// const Wrap = styled.div`
//   position: fixed;
//   height: 100px;
//   ${props => props.theme.fontSizing(props.theme.font.baseSizes)};
//   border-bottom: 1px solid ${props => props.theme.materialTheme.sep};
//   width: calc(80%);
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background: ${props => props.theme.materialTheme.bg};
//   z-index: 1;
//   @media print {
//     display: none;
//   }
//   @media screen and (max-width: ${props => props.theme.responsive.sm}) {
//     width: 100%;
//     height: 80px;
//   }
// `

const IndicatorWrap = styled.div`
  height: 5px;
  width: calc(83.33% - 38px);
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 100;
`

const Indicator = styled.div`
  height: 5px;
  background: ${props => props.theme.colors.highlight1};
  width: ${props => props.width};
`

const Sep = styled.div`
  padding: 0 10px;

  svg {
    position: relative;
    top: 2px;
  }
`

// const Title = styled.div`
//   color: ${props => props.theme.materialTheme.fg};
//   margin-left: auto;
//   margin-right: auto;
//   width: 100%;
//   max-width: calc(50% - 22px);
//   display: flex;
//   height: 100px;
//   align-items: center;
//   a {
//     color: ${props => props.theme.materialTheme.fg};
//     text-decoration: none;
//   }
//   span {
//     display: inline-block;
//     margin-right: 10px;
//     margin-left: 10px;
//     &:first-child {
//       margin-left: 0px;
//     }
//   }
//   em {
//     font-style: normal;
//   }

//   svg {
//     position: relative;
//     top: 2px;
//   }

//   @media screen and (max-width: ${props => props.theme.responsive.sm}) {
//     display: none;
//   }
// `

const Code = styled.span`
  opacity: 0.5;
  text-transform: uppercase;
  padding-left: 0.5em;
  display: inline-block;
`

// const SearchWrap = styled.div`
//   position: absolute;
//   left: calc(75% + 11px);
//   top: 0;
//   height: 100px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   width: calc(20% - 11px);
//   @media screen and (max-width: ${props => props.theme.responsive.sm}) {
//     height: 80px;
//     left: auto;
//     right: 64px;
//   }
// `

// const MobileHome = styled.div`
//   display: none;
//   @media screen and (max-width: ${props => props.theme.responsive.sm}) {
//     display: block;
//   }
//   a {
//     display: none;
//     width: 45px;
//     height: 45px;
//     border-radius: 45px;
//     margin-left: 20px;
//     background: ${props => props.theme.colors.highlight1};
//     @media screen and (max-width: ${props => props.theme.responsive.sm}) {
//       display: block;
//     }
//   }
// `
